import React from "react";
import "./informationSection.css";
import {
  DatePicker,
  LocalizationProvider,
  TimePicker
} from "@mui/x-date-pickers";
import { Checkbox, FormControlLabel } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { getTranslation } from "../../../../utils/getTranslation";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import "dayjs/locale/es";

const InformationSection = ({
  className,
  payload,
  handleChangeDateTime,
  handleChangeCheckbox,
  errors
}) => {
  const { t, i18n } = useTranslation();
  return (
    <div className={className + " info"}>
      <h3>{getTranslation("Block information", t)}</h3>
      <div className="info-date">
        <span>{getTranslation("Select date for blocking", t)}</span>
        <div className="row align-items-end">
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale={i18n.language}
          >
            <div
              className={
                "col-12 col-sm-4 p-0 pe-sm-4" +
                (errors.startDate ? " error" : "")
              }
            >
              <DatePicker
                className="calendar-input"
                label={getTranslation("Start Date", t)}
                value={payload?.startDate ?? null}
                onChange={(newValue) =>
                  handleChangeDateTime(newValue, "startDate")
                }
                minDate={dayjs(new Date())}
              />
              {errors.startDate && (
                <span className="error">
                  {getTranslation("Fill in this field correctly.", t)}
                </span>
              )}
            </div>
            <div
              className={
                "col-12 col-sm-4 p-0 px-sm-4" + (errors.endDate ? " error" : "")
              }
            >
              <DatePicker
                className="calendar-input"
                label={getTranslation("End Date", t)}
                value={payload?.endDate ?? null}
                onChange={(newValue) =>
                  handleChangeDateTime(newValue, "endDate")
                }
                minDate={dayjs(new Date())}
              />
              {errors.endDate && (
                <span className="error">
                  {getTranslation("Fill in this field correctly.", t)}
                </span>
              )}
            </div>
          </LocalizationProvider>
        </div>
      </div>
      <div className="info-time">
        <span>{getTranslation("Select the time or time for blocking", t)}</span>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <div className="row align-items-end">
            <div
              className={
                "col-12 col-sm-4 p-0 pe-sm-4" +
                (errors.startTime ? " error" : "")
              }
            >
              <TimePicker
                className="time-picker-input"
                label={getTranslation("Start Time", t)}
                disabled={payload?.isAllDay}
                value={payload?.startTime ?? null}
                onChange={(newValue) =>
                  handleChangeDateTime(newValue, "startTime")
                }
              />
              {errors.startTime && (
                <span className="error">
                  {getTranslation("Fill in this field correctly.", t)}
                </span>
              )}
              {errors.invalidSchedule && (
                <span className="error">
                  {getTranslation("Invalid blocking schedule.", t)}
                </span>
              )}
            </div>
            <div
              className={
                "col-12 col-sm-4 p-0 px-sm-4" + (errors.endTime ? " error" : "")
              }
            >
              <TimePicker
                className="time-picker-input"
                label={getTranslation("End time", t)}
                disabled={payload?.isAllDay}
                value={payload?.endTime ?? null}
                onChange={(newValue) =>
                  handleChangeDateTime(newValue, "endTime")
                }
              />
              {errors.endTime && (
                <span className="error">
                  {getTranslation("Fill in this field correctly.", t)}
                </span>
              )}
              {errors.invalidSchedule && (
                <span className="error">
                  {getTranslation("Invalid blocking schedule.", t)}
                </span>
              )}
            </div>
            <div className="col-12 col-sm-4 p-0 ps-sm-4">
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    name="isAllDay"
                    checked={payload?.isAllDay ?? false}
                    onChange={handleChangeCheckbox}
                  />
                }
                label={getTranslation("Block all day", t)}
              />
            </div>
          </div>
        </LocalizationProvider>
      </div>
    </div>
  );
};

export default InformationSection;
