import React, { useCallback, useEffect, useRef, useState } from "react";
import "./blockCalendarDemoModal.css";
import { Dialog } from "@mui/material";
import { useDispatch } from "react-redux";
import { formatDate, formatTime } from "../../../../utils/formatDateTime";
import { toggleNotification } from "../../../../redux-sagas/actions";
import dayjs from "dayjs";
import InformationSection from "../../../Modals/CreateBlockDemoModal/BlockInformationSection/InformationSection";
import VehicleSection from "../../../Modals/CreateBlockDemoModal/BlockVehicleSection/VehicleSection";
import ReasonSection from "../../../Modals/CreateBlockDemoModal/BlockReasonSection/ReasonSection";
import Button from "../../../Button/Button";
import fetchClient from "../../../../services/fetch";
import { getTranslation } from "../../../../utils/getTranslation";
import { useTranslation } from "react-i18next";
import { format, isEqual } from "date-fns";

const BlockCalendarDemoModal = ({ open, closeModal, calendarSlot }) => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const shouldSubmit = useRef(false);
	const [payload, setPayload] = useState({
		vehicleIds: [],
		startDate: calendarSlot ? dayjs(calendarSlot.start) : null,
		endDate: calendarSlot ? dayjs(calendarSlot.start) : null,
		startTime: calendarSlot ? dayjs(calendarSlot.start) : null,
		endTime: calendarSlot ? dayjs(calendarSlot.end) : null
	});
	const [dropdownData, setDropdownData] = useState({
		brands: [],
		models: [],
		vins: []
	});
	const [dropdownValue, setDropdownValue] = useState({});
	const [errors, setErrors] = useState({});

	useEffect(() => {
		if (!Object.keys(errors).length && shouldSubmit.current) sendRequest();
	}, [errors]);

	const handleChangeDropdown = (e) => {
		setPayload({
			...payload,
			[e.target.name]: e.target.value
		});
	};

	const handleDropdown = (e) => {
		setDropdownValue({
			...dropdownValue,
			[e.target.name]: e.target.value
		});
		if (e.target.name === "vehicleId") {
			setPayload({
				...payload,
				vehicleIds: [e.target.value]
			});
		}
	};

	const handleChangeCheckbox = (e) => {
		const { checked, name } = e.target;
		const newObj = { ...payload, [name]: checked };

		if (checked) {
			newObj.startTime = newObj.endTime = null;
		}

		setPayload(newObj);
	};

	const handleChangeDateTime = (newValue, name) => setPayload({ ...payload, [name]: newValue });

	const validate = () => {
		const obj = {};
		if (!payload.startDate) obj.startDate = true;
		if (!payload.endDate) obj.endDate = true;
		if (!payload.isAllDay) {
			if (!payload.startTime) obj.startTime = true;
			if (!payload.endTime) obj.endTime = true;

			// Validate same start and end date, start time and end time
			if (payload.startDate && payload.endDate &&
				payload.startTime && payload.endTime &&
				isEqual(new Date(payload.startDate), new Date(payload.endDate)) &&
				isEqual(new Date(payload.startTime), new Date(payload.endTime))
			) {
				obj.invalidSchedule = true;
			}
		}
		if (!payload.vehicleIds.length) obj.vehicleId = true;
		if (!payload.reason) obj.reason = true;

		setErrors(obj);
	};

	const sendRequest = () => {
		const obj = {
			...payload,
			startDate: formatDate(payload.startDate),
			endDate: formatDate(payload.endDate)
		};
		const formatted = {};

		if (obj.startDate === obj.endDate) {
			formatted.week = getTranslation(format(new Date(payload.startDate), "EEEE"), t);
			formatted.days = format(new Date(payload.startDate), "dd");
		}

		if (!obj.isAllDay) {
			obj.startTime = formatTime(payload.startTime);
			obj.endTime = formatTime(payload.endTime);

			formatted.startTime = format(new Date(payload.startTime), "p");
			formatted.endTime = format(new Date(payload.endTime), "p");
		}

		fetchClient()
			.post("/blockedvehicles", obj)
			.then(() => {
				dispatch(
					toggleNotification({
						isOpen: true,
						type: "success",
						content1: getTranslation("The block with reason", t),
						highlight1: ` ${getTranslation(obj.reason, t)} `,
						content2: getTranslation("has been successfully created for", t),
						highlight2: ` ${formatted.week} ${formatted.days}, ${formatted.startTime} - ${formatted.endTime}`
					})
				);
			})
			.catch((err) =>
				dispatch(
					toggleNotification({
						isOpen: true,
						type: "error",
						content1: err.response.data.message,
						highlight1: "",
						content2: "",
						highlight2: ""
					})
				)
			);
		closeModal();
	};

	const getDropdownData = useCallback(
		(name) => {
			const params = new URLSearchParams({
				...dropdownValue
			});
			fetchClient()
				.get(`/blockedvehicles/${name}?${params}`)
				.then((res) => {
					setDropdownData((prevState) => ({
						...prevState,
						[name]: res.data.data
					}));
				})
				.catch((err) => console.log(err));
		},
		[dropdownValue]
	);

	const handleSubmitClick = () => {
		validate();
		shouldSubmit.current = true;
	};

	return (
		<Dialog
			className='create-block-demo'
			open={open}
			onClose={closeModal}
		>
			<h2>{getTranslation("Create Block", t)}</h2>
			<InformationSection
				payload={payload}
				errors={errors}
				handleChangeDateTime={handleChangeDateTime}
				handleChangeCheckbox={handleChangeCheckbox}
				className={"order-0"}
			/>
			<VehicleSection
				payload={payload}
				dropdownValue={dropdownValue}
				errors={errors}
				handleChangeDropdown={handleDropdown}
				className={"order-1"}
				mode={"create"}
				selectedMultiple={[]}
				dropdownData={dropdownData}
				getDropdownData={getDropdownData}
			/>
			<ReasonSection
				payload={payload}
				errors={errors}
				handleChangeDropdown={handleChangeDropdown}
				className='order-2'
			/>
			<div className='btn-group order-3'>
				<Button
					onClick={closeModal}
					type='secondary'
					children={getTranslation("Cancel", t)}
				/>
				<Button
					onClick={handleSubmitClick}
					type='primary'
					children={getTranslation("Accept", t)}
				/>
			</div>
		</Dialog>
	);
};

export default BlockCalendarDemoModal;
