import { Checkbox, FormControlLabel } from "@mui/material";
import "./blockScheduleInformation.css";
import Dropdown from "../../../../Dropdown/Dropdown";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker, TimePicker } from "@mui/x-date-pickers";
import { useState } from "react";
import fetchClient from "../../../../../services/fetch";
import reasons from "../../../../../db/reasons";
import { getTranslation } from "../../../../../utils/getTranslation";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import "dayjs/locale/es";

const BlockScheduleInformation = ({
	filters,
	eventProps,
	errors,
	payload,
	onPayloadChange,
	onDateTimeChange,
	onCheckboxChange
}) => {
	const [showroomData, setShowroomData] = useState([]);
	const { t, i18n } = useTranslation();
	const handleOpenDropdown = () =>
		fetchClient()
			.get("/commondata/showrooms")
			.then((res) => setShowroomData(res.data.data))
			.catch((err) => console.error(err));

	return (
		<div className='block-reschedule-information container'>
			<div className='reservation-data'>
				<div className='row'>
					<div className='col-lg-12 ps-0 pe-4 mb-4'>
						<span className='header-text-data'>{getTranslation("Block information", t)}</span>
					</div>
					<div className='col-lg-12 ps-0 pe-4 mb-4'>
						<span>{getTranslation("Select date for blocking", t)}</span>
					</div>
					<LocalizationProvider
						dateAdapter={AdapterDayjs}
						adapterLocale={i18n.language}
					>
						<div className='col-lg-4 ps-0 pe-4 mb-4'>
							<DatePicker
								className='calendar-input'
								label={getTranslation("Start Date", t)}
								disabled={false}
								name='startDate'
								value={payload.startDate}
								onChange={(newValue) => onDateTimeChange(newValue, "startDate")}
								minDate={dayjs(new Date())}
							/>
							{errors.startDate && (
								<span className='error'>{getTranslation("Fill in this field correctly.", t)}</span>
							)}
						</div>
						<div className='col-lg-4 pe-4 ps-0 mb-4'>
							<DatePicker
								className='calendar-input'
								label={getTranslation("End Date", t)}
								name='endDate'
								value={payload.endDate}
								onChange={(newValue) => onDateTimeChange(newValue, "endDate")}
								minDate={dayjs(new Date())}
							/>
							{errors.endDate && (
								<span className='error'>{getTranslation("Fill in this field correctly.", t)}</span>
							)}
						</div>
					</LocalizationProvider>
					<div className='col-lg-12 pe-0 ps-0 mb-4'>
						<span>{getTranslation("Select the time or time for blocking", t)}</span>
					</div>
					<div className='col-lg-4 ps-0 pe-4 mb-4'>
						<LocalizationProvider dateAdapter={AdapterDayjs}>
							<TimePicker
								className='time-picker-input'
								disabled={payload.isAllDay}
								label={getTranslation("Time", t)}
								name='startTime'
								onChange={(newValue) => onDateTimeChange(newValue, "startTime")}
								value={payload.startTime}
							/>
							{errors.startTime && (
								<span className='error'>{getTranslation("Fill in this field correctly.", t)}</span>
							)}
							{errors.invalidSchedule && (
								<span className='error'>
									{getTranslation("Invalid blocking schedule.", t)}
								</span>
							)}
						</LocalizationProvider>
					</div>
					<div className='col-lg-4 pe-4 ps-0 mb-4'>
						<LocalizationProvider dateAdapter={AdapterDayjs}>
							<TimePicker
								className='time-picker-input'
								disabled={payload.isAllDay}
								label={getTranslation("Time", t)}
								name='endTime'
								onChange={(newValue) => onDateTimeChange(newValue, "endTime")}
								value={payload.endTime}
							/>
							{errors.startTime && (
								<span className='error'>{getTranslation("Fill in this field correctly.", t)}</span>
							)}
							{errors.invalidSchedule && (
								<span className='error'>
									{getTranslation("Invalid blocking schedule.", t)}
								</span>
							)}
						</LocalizationProvider>
					</div>
					<div className='col-lg-4 pe-0 ps-4 pt-2'>
						<FormControlLabel
							control={
								<Checkbox
									size='small'
									name='isAllDay'
									checked={payload?.isAllDay ?? false}
									onChange={onCheckboxChange}
								/>
							}
							label={getTranslation("Block all day", t)}
						/>
					</div>
				</div>
			</div>
			<div className='reservation-data'>
				<div className='row'>
					<div className='col-lg-12 ps-0 pe-4 mb-4'>
						<span className='header-text-data'>{getTranslation("Showroom information", t)}</span>
					</div>
					<div className='col-lg-12 ps-0 pe-4 mb-4'>
						<span>{getTranslation("Select showroom", t)}</span>
					</div>
					<div className='col-lg-5 ps-0 pe-4 mb-4'>
						<Dropdown
							name='showroomIds'
							error={errors.showroomIds}
							onOpen={handleOpenDropdown}
							data={showroomData}
							textField='name'
							valueField='id'
							label={getTranslation("Showroom", t)}
							value={payload.showroomIds[0]}
							handleChange={onPayloadChange}
						/>
					</div>
				</div>
				<div className='row'>
					<div className='col-lg-12 ps-0 pe-4 mb-4'>
						<span className='header-text-data'>{getTranslation("Reason for blocking", t)}</span>
					</div>
					<div className='col-lg-12 ps-0 pe-4 mb-4'>
						<span>{getTranslation("Select the reason for blocking", t)}</span>
					</div>
					<div className='col-lg-5 ps-0 pe-4 mb-4'>
						<Dropdown
							name='reason'
							error={errors.reason}
							data={reasons.map((item) => ({
								...item,
								name: getTranslation(item.name, t)
							}))}
							value={payload.reason}
							handleChange={onPayloadChange}
							label={getTranslation("Reason", t)}
							textField='name'
							valueField='id'
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default BlockScheduleInformation;
