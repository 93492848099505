import moment from "moment/moment";
import BigCalendar from "react-big-calendar-like-google";
import "./blockCalendar.css";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadCalendarEvents, toggleNotification } from "../../../redux-sagas/actions";
import BlockCalendarFilters from "./BlockCalendarFilters/BlockCalendarFilters";
import BlockCalendarSearch from "./BlockCalendarSearch/BlockCalendarSearch";
import BlockCalendarModal from "./BlockCalendarModal/BlockCalendarModal";
import CustomEvent from "../CustomEvent/CustomEvent";
import OverlayInsert from "../BlockShowroomCalendar/OverlayInsert/OverlayInsert";
import CustomToolbar from "../CustomToolbar/CustomToolbar";
import dayjs from "dayjs";

import fetchClient from "../../../services/fetch";
import { createNewDate, formatDate, formatEvents } from "../../../utils/formatDateTime";
import BlockCalendarDemoModal from "./BlockCalendarDemoModal/BlockCalendarDemoModal";
import getStartEndDates from "../../../utils/getStartEndDates";
import { useTranslation } from "react-i18next";
import { getTranslation } from "../../../utils/getTranslation";
import getCalendarFormattedDateTime from "../../../utils/getCalendarFormattedDateTime";
import { isEqual } from "date-fns";

BigCalendar.momentLocalizer(moment);

const BlockCalendar = ({ role, calendarParams, setCalendarParams }) => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	// Use Selector
	const calendarEvents = useSelector((state) => state.calendarEvents);

	const initialPayload = {
		showroomIds: [],
		startDate: null,
		endDate: null,
		startTime: null,
		endTime: null,
		isAllDay: false,
		reason: ""
	};

	// Use States
	const [openModal, setOpenModal] = useState(false);
	const [mode, setMode] = useState("create");
	const [filters, setFilters] = useState({
		showroom: { id: "", name: "" },
		location: { id: "", name: "" },
		brand: { id: "", name: "" },
		state: { name: "", value: "" }
	});
	const el = useRef(null);
	const buttonRef = useRef();
	const view = useRef("week");
	const [clicked, setClicked] = useState(null);
	const [calendarSlot, setCalendarSlot] = useState(null);
	const [errors, setErrors] = useState({});
	const shouldSubmit = useRef(false);

	const [payload, setPayload] = useState(initialPayload);

	useEffect(() => {
		queryBuilder();
	}, [calendarParams, filters]);

	useEffect(() => {
		if (!Object.keys(errors).length && shouldSubmit.current) sendRequest();
	}, [errors]);

	// Fetch calendar events
	const queryBuilder = () => {
		dispatch(loadCalendarEvents(calendarParamQuery()));
	};

	const calendarParamQuery = () => {
		return `?search=${calendarParams.search}&showroomId=${filters.showroom.id}&brandId=${
			filters.brand.id
		}&cityId=${filters.location.id}&status=${filters.state.value}&fromDate=${
			calendarParams.fromDate ? calendarParams.fromDate : ""
		}&toDate=${calendarParams.toDate ? calendarParams.toDate : ""}`;
	};

	const onNavigate = (date, view) => {
		const { from, to } = getStartEndDates(date, view);
		setCalendarParams({
			...calendarParams,
			currentDate: date,
			fromDate: from,
			toDate: to
		});
	};

	const calendarView = (props) => {
		view.current = props;
		onNavigate(calendarParams.currentDate, props);
	};

	const validate = () => {
		const obj = {};
		if (!payload.startDate) obj.startDate = true;
		if (!payload.endDate) obj.endDate = true;
		if (!payload.isAllDay) {
			if (!payload.startTime) obj.startTime = true;
			if (!payload.endTime) obj.endTime = true;

			// Validate same start and end date, start time and end time
			if (payload.startDate && payload.endDate &&
				payload.startTime && payload.endTime &&
				isEqual(new Date(payload.startDate), new Date(payload.endDate)) &&
				isEqual(new Date(payload.startTime), new Date(payload.endTime))
			) {
				obj.invalidSchedule = true;
			}
		}
		if (!payload.showroomIds.length) obj.showroomIds = true;
		if (!payload.reason) obj.reason = true;

		setErrors(obj);
	};

	// Custom header for week and day view
	const customWeekDayViewHeader = (props) =>
		getTranslation(moment(props.date).format("ddd"), t) + " " + moment(props.date).format("DD");

	const customMonthHeader = (props) => getTranslation(props.label, t);

	// Handles close modal
	const handleCloseModal = (action) => {
		if (action === "accept") {
			validate();
			shouldSubmit.current = true;
		} else {
			setOpenModal(false);
			setErrors({});
			setPayload(initialPayload);
			shouldSubmit.current = false;
		}
	};

	const blockDemoCloseModal = () => {
		setOpenModal(false);
		queryBuilder();
	};

	// Handles selected event
	const handleSelectEvent = (e) => {
		const today = new Date();
		if ((view.current === "week" || view.current === "day") && e.start >= today) {
			setCalendarSlot(e);
			el.current = document.getElementsByClassName(e.start.toISOString())[
				formatDate(e.start) === formatDate(new Date()) ? 1 : 0
			];
			setClicked(el.current);
			setTimeout(() => buttonRef.current.click(), 100);
			setPayload({
				...payload,
				startDate: dayjs(e.start),
				endDate: dayjs(e.start),
				startTime: dayjs(e.start),
				endTime: dayjs(e.end)
			});
		}
	};

	// Handle date & time Change
	const onDateTimeChange = (newValue, name) => setPayload({ ...payload, [name]: newValue });

	// Handle checkbox change
	const onCheckboxChange = (e) => {
		const { checked, name } = e.target;
		const newObj = { ...payload, [name]: checked };

		if (checked) {
			newObj.startTime = newObj.endTime = null;
		}
		setPayload(newObj);
	};

	// Handle on change block vehicle data
	const onPayloadChange = (e) => {
		setPayload({
			...payload,
			[e.target.name]:
				e.target.name === "showroomIds"
					? [e.target.value]
					: e.target.name === "reason"
					? getTranslation(e.target.value)
					: e.target.value
		});
	};

	const sendRequest = () => {
		const { formattedDateTime, newPayload } = getCalendarFormattedDateTime(payload);
		fetchClient()
			.post("/blockedshowrooms", newPayload)
			.then(() => {
				queryBuilder();
				dispatch(
					toggleNotification({
						isOpen: true,
						type: "success",
						content1: getTranslation("The block with reason", t),
						highlight1: ` ${getTranslation(newPayload.reason, t)} `,
						content2: getTranslation("has been successfully created for", t),
						highlight2: ` ${
							newPayload.startDate === newPayload.endDate
								? `${formattedDateTime.week} ${formattedDateTime.days}`
								: formattedDateTime.multiple
						}, ${formattedDateTime.startTime} - ${formattedDateTime.endTime}`
					})
				);
			})
			.catch((err) =>
				dispatch(
					toggleNotification({
						isOpen: true,
						type: "error",
						content1: getTranslation(err.response.data.message, t),
						highlight1: "",
						content2: "",
						highlight2: ""
					})
				)
			)
			.finally(() => setPayload(initialPayload));

		setOpenModal(false);
	};

	return (
		<div className='block-calendar-container'>
			<div className='filters-container'>
				<BlockCalendarFilters
					filters={filters}
					setFilters={setFilters}
				/>
			</div>
			<div className='search-filters-container'>
				<BlockCalendarSearch
					role={role}
					calendarParams={calendarParams}
					setCalendarParams={setCalendarParams}
				/>
			</div>
			<div className='calendar-container'>
				<BigCalendar
					events={formatEvents(calendarEvents).map((item) => ({
						...item,
						eventTitle: getTranslation(item.eventTitle, t)
					}))}
					timeslots={1}
					startAccessor='eventStartDate'
					endAccessor='eventEndDate'
					titleAccessor='eventTitle'
					defaultDate={new Date()}
					onNavigate={(date, view) => onNavigate(date, view)}
					selectable
					slotPropGetter={(date) => {
						let className = date.toISOString();
						const today = new Date();
						if (today >= date) className += " disabled";
						return { className };
					}}
					onSelectSlot={handleSelectEvent}
					components={{
						toolbar: CustomToolbar,
						event: CustomEvent,
						week: {
							header: customWeekDayViewHeader
						},
						day: {
							header: customWeekDayViewHeader
						},
						month: {
							header: customMonthHeader
						}
					}}
					onView={calendarView}
					defaultView='week'
					min={createNewDate("6:00:00")}
					max={createNewDate("18:30:00")}
					messages={{
						showMore: (total) => `+${total} ${getTranslation("more", t)}`
					}}
				/>
			</div>
			{clicked && (
				<OverlayInsert
					openModal={setOpenModal}
					selected={calendarSlot}
					buttonRef={buttonRef}
					el={el}
				/>
			)}
			{openModal && role !== 4 && (
				<BlockCalendarModal
					errors={errors}
					filters={filters}
					mode={mode}
					open={openModal}
					closeModal={handleCloseModal}
					eventProps={calendarSlot}
					payload={payload}
					onPayloadChange={onPayloadChange}
					onDateTimeChange={onDateTimeChange}
					onCheckboxChange={onCheckboxChange}
				/>
			)}
			{openModal && role === 4 && (
				<BlockCalendarDemoModal
					open={openModal}
					calendarSlot={calendarSlot}
					closeModal={blockDemoCloseModal}
				/>
			)}
		</div>
	);
};

export default BlockCalendar;
